
/**
 * @roxi/routify 2.18.11
 * File generated Wed Oct 02 2024 11:12:49 GMT+0200 (Central European Summer Time)
 */

export const __version = "2.18.11"
export const __timestamp = "2024-10-02T09:12:49.966Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports


//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isDir": true,
      "children": [
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_anon__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/anon/data-usage-policy",
          "id": "_anon_dataUsagePolicy",
          "component": () => import('./_anon__layout_svelte.js').then(m => m._anon_dataUsagePolicy)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_anon__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/anon/data-usage-policy-en",
          "id": "_anon_dataUsagePolicyEn",
          "component": () => import('./_anon__layout_svelte.js').then(m => m._anon_dataUsagePolicyEn)
        },
        {
          "isIndex": true,
          "isPage": true,
          "meta": {
            "$$bundleId": "_anon__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/anon/index",
          "id": "_anon_index",
          "component": () => import('./_anon__layout_svelte.js').then(m => m._anon_index)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_anon__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/anon/login",
          "id": "_anon_login",
          "component": () => import('./_anon__layout_svelte.js').then(m => m._anon_login)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_anon__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/anon/logout",
          "id": "_anon_logout",
          "component": () => import('./_anon__layout_svelte.js').then(m => m._anon_logout)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_anon__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/anon/pin",
          "id": "_anon_pin",
          "component": () => import('./_anon__layout_svelte.js').then(m => m._anon_pin)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_anon__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/anon/terms-of-service",
          "id": "_anon_termsOfService",
          "component": () => import('./_anon__layout_svelte.js').then(m => m._anon_termsOfService)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_anon__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/anon/terms-of-service-en",
          "id": "_anon_termsOfServiceEn",
          "component": () => import('./_anon__layout_svelte.js').then(m => m._anon_termsOfServiceEn)
        }
      ],
      "isLayout": true,
      "ownMeta": {
        "bundle": true
      },
      "meta": {
        "bundle": true,
        "$$bundleId": "_anon__layout_svelte.js",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/anon",
      "id": "_anon__layout",
      "component": () => import('./_anon__layout_svelte.js').then(m => m._anon__layout)
    },
    {
      "isPage": true,
      "path": "/fotografie",
      "id": "_fotografie",
      "component": () => import('../src/pages/fotografie.svelte').then(m => m.default)
    },
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => import('../src/pages/index.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isFallback": true,
          "meta": {
            "$$bundleId": "_uchazec__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/uchazec/_fallback",
          "id": "_uchazec__fallback",
          "component": () => import('./_uchazec__layout_svelte.js').then(m => m._uchazec__fallback)
        }
      ],
      "isLayout": true,
      "ownMeta": {
        "bundle": true
      },
      "meta": {
        "bundle": true,
        "$$bundleId": "_uchazec__layout_svelte.js",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/uchazec",
      "id": "_uchazec__layout",
      "component": () => import('./_uchazec__layout_svelte.js').then(m => m._uchazec__layout)
    },
    {
      "isDir": true,
      "children": [
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_zapisy__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/zapisy/fotografie",
          "id": "_zapisy_fotografie",
          "component": () => import('./_zapisy__layout_svelte.js').then(m => m._zapisy_fotografie)
        },
        {
          "isIndex": true,
          "isPage": true,
          "meta": {
            "$$bundleId": "_zapisy__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/zapisy/index",
          "id": "_zapisy_index",
          "component": () => import('./_zapisy__layout_svelte.js').then(m => m._zapisy_index)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_zapisy__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/zapisy/uchazeci",
          "id": "_zapisy_uchazeci",
          "component": () => import('./_zapisy__layout_svelte.js').then(m => m._zapisy_uchazeci)
        }
      ],
      "isLayout": true,
      "ownMeta": {
        "bundle": true
      },
      "meta": {
        "bundle": true,
        "$$bundleId": "_zapisy__layout_svelte.js",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/zapisy",
      "id": "_zapisy__layout",
      "component": () => import('./_zapisy__layout_svelte.js').then(m => m._zapisy__layout)
    }
  ],
  "path": "/"
}


export const {tree, routes} = buildClientTree(_tree)

